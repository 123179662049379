import { navigate } from "gatsby";
import React, { useEffect } from "react"

const Homepage = ({ }) => {
  useEffect(() => {
    var userLang = navigator.language || navigator.userLanguage;
    userLang = userLang.substring(0, 2)

    if (!["it", "en"].includes(userLang)) {
      userLang = "en"
    }

    navigate(`/${userLang}`)
  })

  return null
}

export default Homepage